.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.timeline ul > li {
  list-style: none;
}

.timeline input {
  text-align: left;
}

.timeline h4 {
  text-decoration: underline;
  font-weight: 900;
  text-align: left;
}

.timeline .timeline-controls-box {
  border: 1px solid #d0d0d0;
  padding: 1.5em;
}

.timeline table {
  margin-top: 5px;
  line-height: 100%;
}

.timeline thead tr {
  text-align: center;
}

.timeline .event-header-column {
  text-align: center;
  vertical-align: top;
}

.timeline .event-column {
  border: 1px solid #e0e0e0;
  padding: 3px;
  height: 10px;
  vertical-align: top;
}

.timeline .event-box {
  font-family: Courier New;
  font-size: 10px;
  border: 1px solid #ffcc80;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 5px;
  margin: 0px 0px 5px 0px;
}

.timeline .timeline-content {
  height: 500px;
}

.timeline h2 {
  text-align: center;
}

.timeline h1 {
  text-align: center;
}

.timeline img {
  width: 3em;
  height: 3em;
}

.timeline-controls-box .active {
  border-bottom: 3px solid #337ab7;
}

.header-table {
  width: 100%;
}

.header-table h2 {
  font-size: 2em;
  line-height: 100%;
}

.header-table h3 {
  font-size: 1.2em;
  line-height: 100%;
}

.header-table p {
  text-align: center;
}

.header-table .side-column {
  width: 20%;
  vertical-align: top;
}

.header-table .left {
  text-align: left;
}

.header-table .right {
  text-align: right;
}

.modal-xlarge {
  width: 90%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* TIMELINE
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

#single-timeline .event-table {
  margin-top: 10px;
  padding: 150px 0;
  transition: all 1s;
}

#single-timeline .event-table tr {
  margin-top: 10px;
}

#single-timeline .event-table tr td {
  position: relative;
  list-style-type: none;
  min-width: 150px;
  height: 100%;
  padding-bottom: 30px;
  background: transparent;
  border-top: 2px solid #ddd;
  vertical-align: top;
}

#single-timeline .event-table tr td::after {
  content: "";
  position: absolute;
  top: -7px;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #f17722;
}

#single-timeline .event-table tr td div {
  position: relative;
  top: 10px;
  left: 5px;
  padding: 15px;
  font-size: 1rem;
  white-space: normal;
  color: black;
  border: solid #f17722;
  background: white;
  margin-top: 8px;
}

#single-timeline .event-table tr td div::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 0;
  left: 0;
  height: 0;
  border-style: solid;
}

#single-timeline .event-table tr td div::before {
  top: -12px;
  left: -3px;
  border-width: 12px 0 0 12px;
  border-color: transparent transparent transparent #f17722;
}

#rotate-timeline .event-table {
  margin-top: 150px;
  padding: 150px 0;
  transition: all 1s;
  width: 100%;
}

#rotate-timeline .event-table tr td {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 160px;
  height: 3px;
  background: transparent;
  border-top: 2px solid #f17722;
}

#rotate-timeline .event-table tr td::after {
  content: "";
  position: absolute;
  top: -7px;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #f45b69;
}

#rotate-timeline .event-table tr td div {
  position: relative;
  left: 5px;
  padding: 15px;
  font-size: 1rem;
  white-space: normal;
  color: black;
  border: solid #f17722;
  background: white;
  margin-top: 8px;
}

#rotate-timeline .event-table tr td div::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 0;
  left: 0;
  height: 0;
  border-style: solid;
}

#rotate-timeline .event-table tr td:nth-child(odd) div {
  top: -170px;
}

#rotate-timeline .event-table tr td:nth-child(odd) div::before {
  top: 100%;
  left: -3px;
  border-width: 12px 12px 0 0;
  border-color: #f17722 transparent transparent transparent;
}

#rotate-timeline .event-table tr td:nth-child(even) div {
  top: 10px;
}

#rotate-timeline .event-table tr td:nth-child(even) div::before {
  top: -12px;
  left: -3px;
  border-width: 12px 0 0 12px;
  border-color: transparent transparent transparent #f17722;
}

.timeline time {
  display: block;
  font-size: 0.98rem;
  font-weight: bold;
  margin-bottom: 8px;
}

/* TIMELINE ARROWS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline .arrows {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.timeline .arrows .arrow__prev {
  margin-right: 20px;
}

.timeline .disabled {
  opacity: 0.5;
}

.timeline .arrows img {
  width: 45px;
  height: 45px;
}

div.row.image-container {
  white-space: normal;
}

/* GENERAL MEDIA QUERIES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 599px) {
  #rotate-timeline .event-table tr,
  #rotate-timeline .event-table tr td {
    width: auto;
  }

  #rotate-timeline .event-table tr {
    padding: 0;
    transform: none !important;
  }

  #rotate-timeline .event-table tr td {
    display: block;
    height: auto;
    background: transparent;
  }

  #rotate-timeline .event-table tr td:first-child {
    margin-top: 25px;
  }

  #rotate-timeline .event-table tr td:not(:first-child) {
    margin-left: auto;
  }

  #rotate-timeline .event-table tr td div {
    width: 94%;
    height: auto !important;
    margin: 0 auto 25px;
    position: static;
  }

  #rotate-timeline .event-table tr td:nth-child(odd) div {
    transform: none;
  }

  #rotate-timeline .event-table tr td:nth-child(odd) div::before,
  #rotate-timeline .event-table tr td:nth-child(even) div::before {
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    border: none;
    border-left: 1px solid white;
    height: 25px;
  }
}
